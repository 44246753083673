import { observable, action, makeObservable } from "mobx"

import api from 'lib/api'

class CaseStore {

  caseId = null
  alerts = null
  caseInfo = null
  paymentPlan = null
  defendantId = null
  defendantInfo = null
  defendantCases = null

  constructor() {
    makeObservable(this, {
      caseInfo: observable,
      caseId: observable,
      alerts: observable,
      paymentPlan: observable,
      defendantCases: observable,
      fetchCase: action.bound,
      createCase: action.bound,
      addIndemnitor: action.bound,
      updateDefendant: action.bound,
      bypassUploads: action.bound,
      caseDecision: action.bound,
      adjustFinances: action.bound,
      generateNewInvoice: action.bound,
      fetchCustomerQuestionnaire: action.bound,
      resetQuestionnaire: action.bound,
      bypass: action.bound,
      swapPrimary: action.bound,
      updateStatus: action.bound,
      updateInfoPacket: action.bound,
      updateDocuments: action.bound,
      adjustCollateral: action.bound,
      resetUploads: action.bound,
      getCombinedDocuments: action.bound,
      getSecureFileUrls: action.bound,
      getSecureUploadsUrl: action.bound,
      deleteIndemnitor: action.bound,
      sendInOfficeLink: action.bound,
      resendUniqueUrl: action.bound,
      messageReceivedTimeline: action.bound,
      toggleAsDefendant: action.bound,
      fetchTimeline: action.bound,
      adjustPaymentPlan: action.bound,
      fetchCasePowers: action.bound,
      saveCasePower: action.bound,
      savePowerFinances: action.bound,
      updatePower: action.bound,
      removeCasePower: action.bound,
      setDefendantAssignee: action.bound,
      setupTestConditions: action.bound,
      fetchAlerts: action.bound,
      fetchDefendant: action.bound
    })
  }

  // Case

  async resetCustomer() {
    this.caseId = null
    this.caseInfo = null
    this.defendantId = null
    this.defendantInfo = null
    this.defendantCases = null
    this.paymentPlan = null
    this.alerts = null
    console.log('Case Store: Resetting')
  }
  
  async fetchDefendant(caseId) {
    const { cases, defendant } = await api.cases.fetchDefendantById(caseId)
    this.defendantId = defendant.id
    this.defendantInfo = defendant
  
    // const agencyId = cases[0].bondsmanId
  
    let casesFinal = []
    let paymentPlan = null
    let caseInfo = null
    // let checkin = null
    for (caseInfo of cases) {
      if(caseInfo.paymentPlanId) paymentPlan = await api.payment_plans.fetchPlanById(caseInfo.paymentPlanId)
      // checkin = null
      // if (caseInfo.checkin?.info?.id) {
      //   checkin = await api.checkins.fetch(agencyId, caseInfo.checkin.info.id)
      //   console.log(checkin)
      // }
      casesFinal.push({ ...caseInfo, paymentPlan: paymentPlan?.plan || null })
    }
    this.defendantCases = casesFinal
  
    if (cases?.length) {
      await this.fetchCase(cases[0].caseId)
    }
    return { cases, defendant }
  }

  async fetchCase(caseId) {
    const { case: caseInfo } = await api.cases.fetchCase(caseId)
    this.caseId = caseInfo.id
    this.caseInfo = caseInfo
    if (caseInfo.paymentPlanId) {
      const { plan } = await api.payment_plans.fetchPlanById(caseInfo.paymentPlanId)
      this.paymentPlan = plan
    }
    if (caseInfo.bondsman.id) {
      const { payload } = await api.alerts.getAgencyAlert(caseInfo.bondsman.id, caseId)
      this.alerts = payload?.watches || []
    }
    return this.caseInfo
  }

  async fetchAlerts(bondsmanId, caseId) {
    const { payload } = await api.alerts.getAgencyAlert(bondsmanId, caseId)
    this.alerts = payload?.watches || []
    return this.alerts
  }

  async createCase(params) {
    const { case: caseInfo } = await api.cases.createCase(params)
    this.caseId = caseInfo.id
    this.caseInfo = caseInfo
    return this.caseInfo
  }

  async addIndemnitor(params) {
    const { case: caseInfo, indemnitorId } = await api.cases.addIndemnitor(this.caseInfo.id, params)
    this.caseId = caseInfo.id
    this.caseInfo = caseInfo
    return indemnitorId
  }

  updateDefendant(params) {
    return api.cases.updateDefendant(this.caseInfo.id, params)
  }

  async setupTestConditions(caseId, conditions) {
    const { case: caseInfo } = await api.cases.setupTestConditions(caseId, { conditions })
    this.caseId = caseInfo.id
    this.caseInfo = caseInfo
    return this.caseInfo
  }

  async bypassUploads(id, type) {
    const results = await api.bondsman.bypassUploads(id, type)
    if (results) {
      return this.fetchCase(this.caseId)
    }
    throw new Error('There was a problem with the Bypass.')
  }

  async setDefendantAssignee(employee) {
    const results = await api.bondsman.setDefendantAssignee(this.caseInfo.id, { employee })
    if (results) {
      return this.fetchCase(this.caseId)
    }
    throw new Error('Problem saving the decision.')
  }

  // Decisions

  async caseDecision(decision) {
    const results = await api.cases.caseDecision(this.caseId, { decision })
    if (results) {
      return this.fetchCase(this.caseId)
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  // Finances

  async adjustFinances(params) {
    const results = await api.bondsman.adjustFinances(this.caseId, params)
    if (results) {
      return this.fetchCase(this.caseId)
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async generateNewInvoice() {
    const results = await api.bondsman.generateInvoice(this.caseId)
    if (results) {
      return this.fetchCase(this.caseId)
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  // Questionnaire

  async fetchCustomerQuestionnaire() {
    if (this.caseId) {
      return api.bondsman.fetchCustomerQuestionnaire(this.caseId)
    }
    throw new Error('Customer not selected.')
  }

  async resetQuestionnaire() {
    if (this.caseId) {
      await api.bondsman.resetQuestionnaire(this.caseId)
      return this.fetchCase(this.caseId)
    }
    throw new Error('Customer not selected.')
  }

  // Case Information

  async notify(infoPacketId) {
    return api.cases.sendGeneralNotification(this.caseId, infoPacketId)
  }

  async swapPrimary(infoId) {
    const results = await api.cases.swapPrimaryIndemnitor(this.caseId, infoId)
    if (results) {
      return this.fetchCase(this.caseId)
    }
    throw new Error('Problem saving the decision.')
  }

  async bypass(id, type) {
    const results = await api.bondsman.bypassUploads(id, type)
    if (results) {
      return this.fetchCase(this.caseId)
    }
    throw new Error('Problem saving the decision.')
  }

  async updateStatus(params) {
    const results = await api.bondsman.updateStatus(this.caseId, params)
    if (results) {
      return this.fetchCase(this.caseId)
    }
    throw new Error('Problem saving the decision.')
  }

  async updateInfoPacket(params) {
    const results = await api.bondsman.updateInfoPacket(this.caseId, params)
    if (results) {
      if (results.error) {
        return results
      }
      return this.fetchCase(this.caseId)
    }
    throw new Error('Problem saving the decision.')
  }

  async updateDocuments(params) {
    const results = await api.bondsman.updateDocuments(this.caseId, params)
    if (results) {
      return this.fetchCase(this.caseId)
    }
    throw new Error('Problem saving the decision.')
  }

  async adjustCollateral(packetId, params) {
    const results = await api.bondsman.adjustCollateral(this.caseId, packetId, params)
    if (results) {
      return this.fetchCase(this.caseId)
    }
    throw new Error('Problem saving the decision.')
  }

  async resetUploads(type) {
    const results = await api.bondsman.resetUploads(this.caseId, type)
    if (results) {
      return this.fetchCase(this.caseId)
    }
    throw new Error('Problem saving the decision.')
  }

  async getCombinedDocuments(id) {
    return api.bondsman.getCombinedDocuments(id)
  }

  async getSecureFileUrls(id) {
    return api.bondsman.getSecureFileUrls(id)
  }

  async getSecureUploadsUrl(id) {
    return api.bondsman.getSecureUploadsUrl(id)
  }

  async deleteIndemnitor(id) {
    const results = await api.bondsman.deleteIndemnitor(id)
    if (results) {
      return this.fetchCase(this.caseId)
    } else {
      throw new Error('Problem saving the decision.')
    }
  }

  async sendInOfficeLink(params) {
    const results = await api.bondsman.sendInOfficeLink(this.caseId, params)
    if (results) {
      return this.fetchCase(this.caseId)
    }
    throw new Error('Problem saving the decision.')
  }

  async resendUniqueUrl(id) {
    return api.bondsman.resendUniqueUrl(id)
  }

  async messageReceivedTimeline(type) {
    const results = await api.bondsman.messageReceivedTimeline(this.caseId, type.toLowerCase())
    if (results) {
      return this.fetchCase(this.caseId)
    }
    throw new Error('Problem saving the decision.')
  }

  async toggleAsDefendant(packetId) {
    const results = await api.bondsman.toggleAsDefendant(this.caseId, packetId)
    if (results) {
      return this.fetchCase(this.caseId)
    }
    throw new Error('Problem saving the decision.')
  }

  // Timeline

  fetchTimeline(type) {
    if (!this.caseId) return Promise.resolve()
    return api.bondsman.fetchTimeline(this.caseId, type)
  }

  saveTimelineNote(type, params) {
    return api.bondsman.saveTimelineNote(this.caseId, type, params)
  }

  // Payment Plan

  async adjustPaymentPlan(params) {
    const results = await api.payment_plans.adjustPaymentPlan(this.caseId, params)
    if (results) {
      return this.fetchCase(this.caseId)
    }
    throw new Error('Could not adjust payment plan.')
  }

  // Powers

  fetchCasePowers(caseId) {
    return api.powers.fetchCasePowers(caseId)
  }

  async saveCasePower(caseId, data) {
    const results = await api.powers.saveCasePower(caseId, data)
    if (results) {
      return this.fetchCase(this.caseId)
    }
    throw new Error('Error saving Case Power')
  }

  async savePowerFinances(caseId, powerId, finances) {
    const results = await api.powers.savePowerFinances(caseId, powerId, { finances })
    if (results) {
      await this.fetchCase(this.caseId)
      return results.power
    }
    throw new Error('Error save Case Power Finances')
  }

  async updatePower(caseId, powerId, params) {
    const results = await api.powers.updatePower(caseId, powerId, params)
    if (results) {
      await this.fetchCase(this.caseId)
      return results.power
    }
    throw new Error('Error save Case Power Finances')
  }

  async removeCasePower(powerId) {
    const results = await api.powers.removeCasePower(this.caseId, powerId)
    if (results) {
      await this.fetchCase(this.caseId)
      return results.power
    }
    throw new Error('Error removing Case Power')
  }

}

export default CaseStore
